import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactElement, useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { FollowButton } from '@common/FollowButton'
import { UrlGrid, UrlGridLoading } from '@desktop/UrlGrid'
import UrlHelper from '@lib/UrlHelper'
import { IntoNode } from '@models/IntoNode'
import { filterResourcesByType } from '@models/RecommendResponse'
import feedApi from '@redux/api/feedApi'
import nodeApi from '@redux/api/nodeApi'
import { mergeCurrentContext } from '@redux/slices/analyticsSlice'
import { selectMediaTypes, setPageSection } from '@redux/slices/appSlice'
import {
  feedConstants,
  setCurrentFeedItemKey,
  setIdentifier,
  setInstanceId,
  setPageNumber,
  setPageSize,
  setStreamUrlId,
} from '@redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { useToggleNodeFollow } from '../../node/hooks/useToggleNodeFollow'

const NodeUrlGrid = ({
  node,
  header,
  _instanceId,
  currentUrlID,
  limit,
  onItemClick,
  contextId,
}: {
  contextId: string | number | null
  header?: ReactElement
  node: IntoNode
  _instanceId: string
  currentUrlID: string | number | null
  limit: number
  onItemClick: (feedItemKey: string) => void
}) => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const mediaTypes = useAppSelector(selectMediaTypes)

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
    initialInView: false,
  })

  const {
    data: feed,
    isLoading,
    isFetching,
    isUninitialized,
  } = feedApi.useGetFeedDataQuery(
    {
      page: 1,
      pageSize: limit,
      contextId,
      slug: node.slug,
      mediaTypes: Array.from(mediaTypes),
      _instanceId,
      filterIds: currentUrlID ? [currentUrlID] : [],
    },
    { skip: !inView || !currentUrlID }
  )

  const { items } = feed ?? {}

  const urls = filterResourcesByType(items, 'url')
    .filter(url => url.url_id !== currentUrlID)
    .splice(0, limit)

  const itemClickHandler = useCallback(
    (feedItemKey: string) => {
      onItemClick(feedItemKey)
      dispatch(setInstanceId(_instanceId))
      dispatch(setIdentifier(node))
      dispatch(setPageSection(null))
      dispatch(setPageSize(feedConstants.FULL_SCREEN_PAGE_SIZE))
      dispatch(setPageNumber(1))
      dispatch(setStreamUrlId(currentUrlID ?? null))
      const queryString = `?skipSSR=true`
      const asPath = `/!${feedItemKey}`
      const href = `/!${feedItemKey}${queryString}`
      dispatch(setCurrentFeedItemKey(feedItemKey))
      router.push(href, asPath).catch(() => {})
    },
    [onItemClick, _instanceId, currentUrlID, dispatch, node, router]
  )

  if (isLoading || isFetching || isUninitialized)
    return (
      <div>
        {!!header && <div className="mb-3 h-6 animate-pulse bg-contrast/10" />}
        <div ref={ref} className="grid grid-cols-2 gap-1 overflow-hidden rounded">
          <UrlGridLoading limit={limit} />
        </div>
      </div>
    )
  return (
    <div>
      {urls?.length > 0 && header}
      <div ref={ref} className="grid grid-cols-2 gap-1 overflow-hidden rounded">
        <UrlGrid urls={urls} isTitleEnabled={false} likedByUsername={undefined} onClick={itemClickHandler} />
      </div>
    </div>
  )
}

export const RelatedUrlGrid = () => {
  const currentFeedItemKey = useAppSelector(state => state.feed.currentFeedItemKey)

  const _instanceId = `related-${currentFeedItemKey}`

  const dispatch = useAppDispatch()
  const onItemClick = () => {
    dispatch(
      mergeCurrentContext({
        pageSection: null,
        streamType: 'RELATED_ARTICLE_STREAM',
      })
    )
  }

  return (
    <NodeUrlGrid
      header={<p className="mb-3 font-medium opacity-75">You might also like</p>}
      contextId={currentFeedItemKey ?? null}
      limit={feedConstants.RELATED_GRID_PAGE_SIZE}
      onItemClick={onItemClick}
      node={IntoNode.RELATED}
      currentUrlID={currentFeedItemKey}
      _instanceId={_instanceId}
    />
  )
}

const ChannelNodeGridHeader = ({ slug }: { slug: string }) => {
  const { data: node } = nodeApi.useGetNodeDetailsQuery({ slug })
  const handleFollow = useToggleNodeFollow(node)

  if (!node || !node.isFollowable) return null
  return (
    <div className="flex h-12 items-center justify-between">
      <Link
        href={UrlHelper.nodePath(node.slug)}
        className="btn btn-link line-clamp-2 shrink pr-4 text-left font-medium opacity-75 hover:underline"
      >
        {node.meta.isChannel ? '' : '# '}
        {node.name}
      </Link>
      <FollowButton className="flex w-fit items-center py-2" isFollowing={node.isFollowing} onFollow={handleFollow} />
    </div>
  )
}

export const ChannelNodeGrid = ({ node }: { node: IntoNode }) => {
  const currentFeedItemKey = useAppSelector(state => state.feed.currentFeedItemKey)
  const [_instanceId] = useState(() => Date.now().toString())
  const dispatch = useAppDispatch()
  const onItemClick = () => {
    dispatch(
      mergeCurrentContext({
        pageSection: null,
        streamType: 'NODE_STREAM',
      })
    )
  }

  return (
    <div className="flex flex-col space-y-6">
      <ChannelNodeGridHeader slug={node.slug} />
      <NodeUrlGrid
        contextId={null}
        limit={feedConstants.NODE_GRID_PAGE_SIZE}
        onItemClick={onItemClick}
        node={node}
        currentUrlID={currentFeedItemKey}
        _instanceId={_instanceId}
      />
    </div>
  )
}
