import { UrlDebugInfo } from '@desktop/UrlDebugInfo'
import IconInfo from '@icons/IconInfo.svg'
import IconStarColored from '@icons/IconStarColored.svg'
import IconThumbUpColored from '@icons/IconThumbUpColored.svg'
import { IntoUrl } from '@models/IntoUrl'
import { selectShowDebugInfo } from '@redux/slices/tweaksSlice'
import { useAppSelector } from '@redux/store/store'

export const UrlMetaDebugInfo = ({
  url,
  className,
  onClick,
}: {
  url: IntoUrl
  className?: string
  onClick?: (e: React.MouseEvent) => void
}) => {
  const showDebugInfo = useAppSelector(selectShowDebugInfo)

  if (!showDebugInfo) {
    return null
  }

  return (
    <UrlDebugInfo
      url={url}
      buttonClassName={`btn flex items-center bg-transparent p-2 opacity-70 hover:opacity-100 ${className}`}
      onClick={onClick}
    >
      <IconInfo className="size-4" />
      {url.meta.isContextualRec && <IconThumbUpColored className="size-4" />}
      {url.meta.isSampledRec && <IconStarColored className="size-4" />}
    </UrlDebugInfo>
  )
}
