import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'

import { globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'

import MediaType = globals.MediaType

type MediaComponentType = 'unknown' | 'UrlMedia' | 'ArticleCard'

interface ICurrentUrlContextProps {
  currentUrl: IntoUrl | undefined
  isFullScreen: boolean
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>
  perceivedMediaType: MediaType
  setPerceivedMediaType: React.Dispatch<React.SetStateAction<MediaType>>
  mediaComponentType: MediaComponentType
  setMediaComponentType: React.Dispatch<React.SetStateAction<MediaComponentType>>
}

export const CurrentUrlContext = createContext<ICurrentUrlContextProps | undefined>(undefined)

export const CurrentUrlContextProvider = ({
  children,
  currentUrl,
}: {
  children: ReactNode
  currentUrl: IntoUrl | undefined
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [perceivedMediaType, setPerceivedMediaType] = useState<MediaType>(MediaType.UNKNOWN_MEDIA_TYPE)
  const [mediaComponentType, setMediaComponentType] = useState<MediaComponentType>('unknown')

  useEffect(() => {
    return () => {
      setPerceivedMediaType(MediaType.UNKNOWN_MEDIA_TYPE)
      setMediaComponentType('unknown')
    }
  }, [currentUrl?.url_id])

  const value: ICurrentUrlContextProps = useMemo(
    () => ({
      currentUrl,
      isFullScreen,
      setIsFullScreen,
      perceivedMediaType,
      mediaComponentType,
      setPerceivedMediaType,
      setMediaComponentType,
    }),
    [
      isFullScreen,
      currentUrl,
      perceivedMediaType,
      mediaComponentType,
      setIsFullScreen,
      setMediaComponentType,
      setPerceivedMediaType,
    ]
  )

  return <CurrentUrlContext.Provider value={value}>{children}</CurrentUrlContext.Provider>
}
export const useCurrentUrlContext = (): ICurrentUrlContextProps => {
  const result = useContext(CurrentUrlContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}
