import React from 'react'

import { useIsDesktop } from '@hooks/useIsDesktop'
import LoginButton from './login/LoginButton'

const SignUpToSeeAllContent = (props: { className?: string }) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={`col-span-full p-4 text-center lg:p-6 ${props.className}`}>
      <p className="mb-3 text-sm font-medium opacity-75">Sign up to see all content</p>
      <LoginButton className="btn btn-primary btn-dot">{isDesktop ? 'Join Mix' : 'Get the app'}</LoginButton>
    </div>
  )
}

export default SignUpToSeeAllContent
