import React from 'react'

import Modal from '@common/Modal'
import { UserListItem } from '@common/UserListItem'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { IntoUser } from '@models/IntoUser'
import SignUpToSeeAllContent from './SignUpToSeeAllContent'

const UserListModalOpacityClasses = [
  'opacity-100',
  'opacity-100',
  'opacity-80',
  'opacity-60',
  'opacity-40',
  'opacity-20',
]

interface UserListModalProps {
  isVisible: boolean
  heading?: string
  users: (IntoUser | undefined)[]
  onClose: () => void
}

export const UserListModal = ({ isVisible, users, heading, onClose }: UserListModalProps) => {
  const { isLoggedIn } = useCurrentUserContext()
  const modalUsers = isLoggedIn ? users : users.slice(0, UserListModalOpacityClasses?.length)
  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <div className="scrollbar-secondary fixed bottom-0 left-0 flex max-h-[80vh] w-full flex-col overflow-hidden rounded-t-xl bg-menu text-menu selection:bg-accent/50 lg:relative lg:h-auto lg:max-h-[80vh] lg:w-[400px] lg:max-w-[90vw] lg:rounded-xl">
        <button onClick={onClose} className="btn absolute right-0 top-0 mt-2 text-sm">
          Close
        </button>
        <div className="flex-1 overflow-auto p-4 lg:p-6">
          <p className="mb-6 text-xl font-medium">{heading}</p>
          <div className="flex-1 space-y-4">
            {modalUsers.map((user, index) =>
              user ? (
                <UserListItem
                  key={user.user_id}
                  userId={user.user_id}
                  className={isLoggedIn ? undefined : UserListModalOpacityClasses[index]}
                />
              ) : (
                <></>
              )
            )}
          </div>
        </div>
        {!isLoggedIn && <SignUpToSeeAllContent className="shrink-0 bg-stone-300" />}
      </div>
    </Modal>
  )
}
