import { useMemo } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useGlobalContext } from '@context/GlobalContexts'
import NodePill from '@desktop/NodePill'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { useIsSmallScreen } from '@hooks/useIsSmallScreen'
import { IntoNode } from '@models/IntoNode'
import { IntoUrl } from '@models/IntoUrl'
import { UrlComments } from '@pages/url/components/UrlComments'
import { useAppSelector } from '@redux/store/store'
import { ChannelNodeGrid, RelatedUrlGrid } from './NodeUrlGrid'
import { UrlActions } from './UrlActions'
import { UrlContextUser } from './UrlContextUser'
import { UrlLikers, UrlLikersLoading } from './UrlLikers'
import { UrlMeta, UrlMetaLoading } from './UrlMeta'
import { UrlShareActions } from './UrlShareActions'
import { UrlShareDetails } from './UrlShareDetails'

interface UrlSidePanelProps {
  url: IntoUrl | undefined | null
}

export const UrlSidePanel = ({ url }: UrlSidePanelProps) => {
  const { isLoggedIn } = useCurrentUserContext()
  const isDesktop = useIsDesktop()
  const isSmallScreen = useIsSmallScreen()
  const likedByUsername = useAppSelector(state => state.user.likedByUsername)
  const curatorUserId = url?.meta.curatorUserID
  const { intoConfig } = useGlobalContext()

  const channelNodes: Set<IntoNode | undefined> = useMemo(() => {
    const urlNodes = url?.nodes ?? []
    return new Set(urlNodes?.filter(node => node?.meta.isChannel) ?? [])
  }, [url])

  const slugNodes: Set<IntoNode | undefined> = useMemo(() => {
    const urlNodes = url?.nodes ?? []
    return new Set(urlNodes?.filter(node => !node?.meta.isChannel) ?? [])
  }, [url])

  return (
    <div className="box-border h-full space-y-8">
      <div className="flex flex-col justify-between space-y-8 px-6">
        <div className="h-fit space-y-8 pb-6">
          {url && !isDesktop && <UrlShareDetails url={url} />}
          {url ? <UrlMeta url={url} /> : <UrlMetaLoading />}
          {curatorUserId && <UrlContextUser userPayload={{ userId: curatorUserId }} heading="Posted by" />}
          {!curatorUserId && likedByUsername && (
            <UrlContextUser userPayload={{ username: likedByUsername }} heading="Liked by" />
          )}
          {isSmallScreen && url && (
            <div className="flex justify-between">
              <UrlActions urlId={url.url_id} />
              <div className="size-10">
                <UrlShareActions url={url} />
              </div>
            </div>
          )}
          {url && isDesktop && <UrlShareDetails url={url} />}
          {!url ? <UrlLikersLoading /> : <UrlLikers url={url} likedByUsername={likedByUsername} />}
          {url && isDesktop && (intoConfig?.displayOptions?.showComments || !isLoggedIn) && <UrlComments url={url} />}
          {channelNodes.size > 0 ? (
            <div className="flex flex-col space-y-8">
              {[...channelNodes].map(node => (node ? <ChannelNodeGrid key={node.slug} node={node} /> : null))}
            </div>
          ) : null}
          {slugNodes.size > 0 ? (
            <div className="flex flex-wrap">
              {[...slugNodes].map(node => (node ? <NodePill key={node.slug} slug={node.slug} /> : null))}
            </div>
          ) : null}
          <RelatedUrlGrid />
        </div>
        {isDesktop && !isLoggedIn && <div className="h-52" />}
      </div>
    </div>
  )
}
