import { useMemo } from 'react'

import { resolveMedia } from '@lib/mediaResolver'
import { globals } from '@lib/messages/protobuf'
import { IntoUrl } from '@models/IntoUrl'

export function useIsArticle(url?: IntoUrl): boolean {
  return useMemo(() => {
    if (!url) return false

    const { resolvedMediaType } = resolveMedia(url)
    const isWebsite = url.meta.isWebsite

    const { AUDIO, VIDEO, GIF } = globals.MediaType
    const isPlayable = resolvedMediaType && [VIDEO, AUDIO, GIF].includes(resolvedMediaType)

    if (isPlayable) return false
    if (!isWebsite) return false

    return true
  }, [url])
}
