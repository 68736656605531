import React from 'react'

import { IntoUrl } from '@models/IntoUrl'
import { UrlContextUser } from './UrlContextUser'

export const UrlShareDetails = ({ url }: { url: IntoUrl }) => {
  if (!url.shares) return null
  return (
    <div className="flex flex-col space-y-4">
      {url.shares.map(
        share =>
          share.sender && (
            <UrlContextUser
              key={share.sender.user_id}
              userPayload={{ username: share.sender.username }}
              hideFollowButton={true}
            >
              <div className="flex min-w-0 flex-1 flex-col">
                <p className="truncate font-medium">
                  {share.sender.display_name}
                  {share.caption && ':'}
                </p>
                {share.caption ? (
                  <p className="text-sm text-accent">{share.caption}</p>
                ) : (
                  <p className="truncate text-sm text-white/50">Shared with you</p>
                )}
              </div>
            </UrlContextUser>
          )
      )}
    </div>
  )
}
